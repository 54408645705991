<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (COOP LOAN)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'">
          <v-form ref="form2" class="multi-col-validation">
<!--            <v-checkbox v-model="is_search" label="Search?"></v-checkbox>-->
            <v-layout col>
              <v-flex xs12 md6 class="mx-2">
                <v-text-field outlined v-model="last_name"
                              @keyup.enter="searching"
                              label="Last Name"></v-text-field>
              </v-flex>
              <v-flex xs12 md6 class="mx-2">
                <v-text-field  outlined v-model="first_name"
                              @keyup.enter="searching"
                              label="First Name"></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.type }}
                </td>
                <td>
                  {{ formatPrice(item.amount_due )}}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter,
    mdiTransferRight,
    mdiCalculator,
    mdiMinusBox,
    mdiFolderSwap,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      selection: '',
      Recalculate: false,
      Transfer: false,
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Type', value: 'type', sortable: false},
        {text: 'Amount Due', value: 'amount_due', sortable: false},
        {text: 'Balance', value: 'balance', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      data_items3: [],
      search_items: [],
      search_itemss: [],
      category_items: [],
      is_search: false,
      status: '',
      last_name: '',
      first_name: '',
      search: '',
      searchh: '',
      alert_message_customize: '',
      mode: '',
      namee: '',
      alert_customize: false,
      isLoading: false,
      is_disable: false,
      total_amount: '0',
      total_balance: '0',
      total_borrower_loan: '0',
      company_share: '0',
      borrower_share: '0',
      monthly_due: '0',
      terms: '0',
      loan_amount: '0',
      id: 0,
      employee_id: 0,
      category_id: 0,
      branch: '',
      key: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiCalculator,
          mdiMinusBox,
          mdiFolderSwap,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD')) {
        var string_name = this.name_credit.split(', ')
        this.last_name = string_name[0]
        this.first_name = string_name[1]
        this.searching()
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact', 'name_credit', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('auto_loan', ['list_of_auto_loan', 'search_auto_loan', 'change_to_bod_charge', 'change_to_terminate', 'recalculate_auto_loan', 'transfer_auto_loan']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('request', ['search_coop_loan_to_coop_api',]),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected() {
        this.employee_id = 0
      },
      searching() {
        this.data_items = []
        this.total_amount = '0'
        this.total_balance = '0'
        this.search_coop_loan_to_coop_api({
          last_name: this.last_name,
          first_name: this.first_name,
        })
          .then(response => {
            this.data_items = response.data.response
            this.isLoading = false
          })
      },
    }
  }
</script>
